import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const TalentIllustration = (props: IIconProps) => {
  const { width = 321, height = 320, className } = props;

  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 321 320"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="#fff" d="M1 0h320v320H1z" />
      <path
        d="M306.6 312.533L12.733 29.867l40 282.666H306.6z"
        stroke="#000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M177.005 159.986c-5.723.528-13.295-.793-13.295-.793l-31.256 10.831s-20.779 36.72-44.64 66.219C63.954 265.742 38.508 312.5 38.508 312.5h35.13l-18.842-6.164s9.51-15.234 32.842-40.946c23.332-25.713 53.268-61.376 53.268-61.376s-3.17 9.775-23.772 45.173C96.531 284.586 88.871 312.5 88.871 312.5h33.633l-19.898-7.397c27.727-53.77 59.061-80.436 74.399-145.117z"
        className="fillBlack"
        fill="#000"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M155.61 102.661c-1.233 18.756-8.012 40.858-8.012 40.858l-15.144 26.505c6.515 4.931 12.59 6.34 16.552 6.605 3.17.264 6.164-1.145 8.013-3.787 14.439-20.429 22.275-86.912 22.011-91.49-.176-4.844 2.554-4.14 2.554-4.14s11.71-10.566 15.144-14.44c0 0-2.818-3.347 1.497-6.34"
        fill="#fff"
      />
      <path
        d="M155.61 102.661c-1.233 18.756-8.012 40.858-8.012 40.858l-15.144 26.505c6.515 4.931 12.59 6.34 16.552 6.605 3.17.264 6.164-1.145 8.013-3.787 14.439-20.429 22.275-86.912 22.011-91.49-.176-4.844 2.554-4.14 2.554-4.14s11.71-10.566 15.144-14.44c0 0-2.818-3.347 1.497-6.34"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M189.596 77.565c3.874.264 6.692.792 7.66 1.497 2.113 1.673.441 8.101-1.232 12.856-1.409 4.05-2.29 8.189-2.554 12.504-.88 13.209-3.169 42.091-6.251 58.558-.792 4.227-4.314 7.397-8.54 7.837l-2.642.264"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M158.956 99.315c-29.319 28.53-62.425 69.3-62.425 69.3s-4.843 11.36-7.044 13.649c-2.113 2.378.617-7.22 1.585-8.365.969-1.145-10.125 7.221-15.848 7.397 0 0 6.603-5.284 10.125-9.158 3.522-3.875 7.044-8.366 7.044-8.366s19.106-35.486 31.168-42.355c0 0 25.622-37.688 31.257-41.21 3.346-2.026 16.024-3.082 26.766-2.994"
        fill="#fff"
      />
      <path
        d="M158.956 99.315c-29.319 28.53-62.425 69.3-62.425 69.3s-4.843 11.36-7.044 13.649c-2.113 2.378.617-7.22 1.585-8.365.969-1.145-10.125 7.221-15.848 7.397 0 0 6.603-5.284 10.125-9.158 3.522-3.875 7.044-8.366 7.044-8.366s19.106-35.486 31.168-42.355c0 0 25.622-37.688 31.257-41.21 3.346-2.026 16.024-3.082 26.766-2.994M189.596 77.565s5.106 21.838 2.113 49.047"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M176.389 77.3s-5.987 4.844-6.163 9.247c-.176 4.403 4.138 8.013 3.081 8.805-1.056.793-7.924 4.227-6.251 10.655 1.673 6.428 5.283 15.498 4.579 23.952M181.672 77.213s2.817 3.962 4.666 2.994c1.849-.97 8.541-7.661 8.541-7.661s.704 3.962 4.314 3.346c3.61-.617 11.975-8.542 14.792-14.09M188.011 51.06s21.396 12.328 38.124 12.592M192.678 109.97c-3.786 27.121-8.101 40.506-10.302 46.053-.88 2.202-2.905 3.787-5.371 3.963"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M203.09 69.333s2.984-2.084 1.492-4.168c-1.492-2.085-4.973-1.226-2.983-1.104 1.989.123 5.097-2.697 2.113-5.394M214.947 61.333s-2.719 4.972 3.291 6.96c6.01 1.99.143 1.99 1.431 4.102 1.288 2.114-2.862 3.605-2.862 3.605"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M193.118 66.558s2.113-1.761 1.145-4.227c-.969-2.466-.441-3.963-.441-3.963s-3.169-3.522-.704-4.578l5.107 2.73s-4.05 2.817-1.497 6.34l-3.61 3.698z"
        className="fillBlack"
        fill="#000"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.132 157.961s1.144-8.366 6.163-14.794c5.019-6.428 21.395-27.826 21.395-56.532-.088.088 5.635 40.33-27.558 71.326z"
        className="fillBrown"
        fill="#C9B098"
        stroke="#C9B098"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M198.049 56.079s6.251-9.51 8.628-13.473c0 0 3.522 3.346 5.195 2.994 1.673-.352 3.874-.792 3.874-.792s1.761 7.132 0 16.907c-.088.088-12.59-2.818-17.697-5.636z"
        className="fillBlack"
        fill="#000"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M205.004 53.261s6.163 2.818 12.855 3.61l-12.855-3.61z" fill="#fff" />
      <path
        d="M205.004 53.261s6.163 2.818 12.855 3.61"
        stroke="#fff"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 312.271h305.469M1.88 183.849s3.698-.352 4.05-4.491c0 0 .529 5.108 3.698 6.252 0 0-4.666.177-5.635 4.139M141.258 53.79s6.516.88 7.044-5.812c0 0 .616 7.308 5.987 7.749 0 0-4.93.088-6.515 3.962 0-.088.704-6.076-6.516-5.9zM241.632 105.391s7.22.528 7.308-7.045c0 0 1.056 6.516 7.132 7.045 0 0-8.189 2.201-8.013 7.749 0 .088-.528-8.013-6.427-7.749zM222.613 163.332v3.434M216.362 166.15l1.937 1.761M213.457 173.106l3.17-.616M216.803 178.478l1.496-1.145M222.613 180.943v-2.289M229.129 178.478l-2.465-1.849M230.978 172.49h-3.169M229.129 165.974l-2.73 2.289"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(TalentIllustration);
