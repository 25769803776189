import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const ShakeHandsIllustration = (props: IIconProps) => {
  const { width = 276, height = 323, className } = props;

  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 276 323"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M203.277 144.675c1.705 8.92-3.409 44.254 3.819 74.886 8.661 36.717 17.117 23.649 30.143 90.929.34 1.59-.614 3.25-2.115 3.803l-16.162 6.638h25.642c1.432 0 2.659-1.107 2.727-2.559 2.455-35.68 3.683-71.014-7.228-93.002-19.027-38.446 6.887-84.775-5.456-100.195L200.345 124s-7.706 70.253-3.205 114.024c3.819 36.786 2.796 64.652 2.387 72.604-.069 1.383-.955 2.558-2.251 2.973L179 321h26.937c2.182 0 3.955-1.59 4.296-3.734 4.024-25.93 10.911-41.281 11.525-61.057"
        fill="#fff"
      />
      <path
        d="M203.277 144.675c1.705 8.92-3.409 44.254 3.819 74.886 8.661 36.717 17.117 23.649 30.143 90.929.34 1.59-.614 3.25-2.115 3.803l-16.162 6.638h25.642c1.432 0 2.659-1.107 2.727-2.559 2.455-35.68 3.683-71.014-7.228-93.002-19.027-38.446 6.887-84.775-5.456-100.195L200.345 124s-7.706 70.253-3.205 114.024c3.819 36.786 2.796 64.652 2.387 72.604-.069 1.383-.955 2.558-2.251 2.973L179 321h26.937c2.182 0 3.955-1.59 4.296-3.734 4.024-25.93 10.911-41.281 11.525-61.057"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M199.274 183.019l62.052 6.426c2.187.218 3.827 2.178 3.663 4.356l-3.007 36.485c-.164 2.232-2.132 3.866-4.319 3.703l-62.818-4.03c-2.296-.163-4.045-2.178-3.827-4.465l3.773-38.881c.218-2.178 2.241-3.811 4.483-3.594zM174.227 97.8s23.954-8.42 30.798-34.982c0 0 12.731 34.913-7.87 52.096 0 0-8.077 0-22.928-17.114z"
        className="fillBlack"
        fill="#000"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M194.76 15.992s-2.807 12.597 1.437 22.66c2.738 6.435 8.829 1.027 8.829 1.027s1.163 6.846 6.844 14.855l4.586-2.259s-4.654-10.405-5.818-19.51c2.943 1.916 5.133-2.123 4.449-6.093-.479-2.944-2.875-5.135-6.639-.685-3.08-3.56-9.787-3.902-13.004-8.9-2.327-3.56-1.711-6.503.958-7.872 4.791-2.465 14.099-4.655 22.312.822 7.46 4.997 7.118 11.637 4.381 16.703-2.054 3.765-5.75 6.162-6.913 8.626 0 0 2.532 4.655 10.472 4.313 8.555-.343 10.676 3.286 10.266 6.846-.137 1.163 4.175-.89 7.802 3.765 3.97 5.066 1.643 12.87-5.544 13.691M174.227 97.8S160.689 99.672 147 102c-13.688 2.328-17.261 6.068-17.261 8.396 0 2.328 5.681-2.259 10.267-3.423 4.585-1.164 13.688 3.423 13.688 5.682 0 2.259-4.586 1.164-4.586 1.164 0 3.959-3.392 5.424-6.844 4.587-3.32 4.756-10.969-.671-13.688-3.423 0 0-9.308 1.917-15.468 2.67"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M151.436 110.054s9.102-.89 20.464-.89c11.43 0 13.209-.616 13.209-.616M224 185.171l.814-4.935c.434-2.632 2.279-4.474 4.341-4.211l11.938 1.513c2.008.264 3.581 2.369 3.69 5.001L245 188M48.157 49.058s2.943-6.025 4.106-10.543c0 0 3.422 3.491 5.68 3.423 3.08-.069 10.267-9.105 8.009-29.642"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.534 321.315H59.86c-1.642 0-3.011-1.3-3.08-3.012-.136-4.997-.547-15.266-2.258-24.371-2.259-12.528-14.852-34.229-12.525-57.025 2.259-22.797 6.16-106.932 6.16-106.932l8.623.89s14.442 60.449 14.852 88.927c.343 26.083-2.121 63.392-.205 91.665.069 1.438 1.027 2.602 2.395 3.081l19.712 6.777z"
        className="fillBlack"
        fill="#000"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.677 227.802c5.886-21.633 9.103-96.937 9.103-96.937l-34.22-3.423s-10.267 17.115-5.682 38.747c4.586 21.633 11.43 36.489-1.163 72.977-11.704 34.16-10.472 60.311-13.962 78.521-.342 1.917 1.095 3.628 3.011 3.628h31.963s-9.24-3.354-19.506-6.366c-.89-.274-1.3-1.301-1.096-2.259 8.898-32.449 24.845-60.175 31.552-84.888z"
        className="fillBlack"
        fill="#000"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.666 46.867s9.24 3.834 18.959 33.818c7.117 21.975 46.951 24.851 46.951 24.851s10.061 1.369 18.068-.89C136.652 102.386 139 103.5 139 103.5M29.677 52.96S2.985 86.64 2.985 107.11c0 17.115 19.574 20.264 19.574 20.264"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.453 81.438c1.3 6.64-3.285 28.342 22.654 40.322 26.35 12.186 53.591 1.164 53.591 1.164v-16.909"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.628 127.442s-6.844 0-8.008-11.432c-1.026-10.406 5.612-40.733 12.32-57.505 5.954-14.992 15.125-13.897 15.125-13.897s3.696-7.6 2.601-14.24c0 0-2.327 1.164-3.832-2.122-1.438-3.012-1.164-6.845 1.163-6.845 2.259 0 4.586 3.422 4.586 3.422s3.422-6.845 8.007-6.845c4.586 0 12.388-1.027 15.947-9.105 3.285-7.325-4.585-6.846-13.688-6.846-9.103 0-18.274-2.738-22.791 7.53 0 0-5.681 10.748 5.68 22.112 0 0 3.833 3.355 4.175 7.668M56.78 130.865s4.996-.411 4.517-8.078"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M236.03 131.413s-2.533 15.403-4.791 29.094c-2.259 13.692-.685 20.88.479 21.975 1.163 1.096 2.943-2.601 2.943-2.601s3.285 1.848 4.585 0c1.301-1.917 4.723.068 4.586-3.423-.205-4.518 1.3-9.379-3.833-15.335 0 0 2.875-12.938 11.225-27.04"
        fill="#fff"
      />
      <path
        d="M236.03 131.413s-2.533 15.403-4.791 29.094c-2.259 13.692-.685 20.88.479 21.975 1.163 1.096 2.943-2.601 2.943-2.601s3.285 1.848 4.585 0c1.301-1.917 4.723.068 4.586-3.423-.205-4.518 1.3-9.379-3.833-15.335 0 0 2.875-12.938 11.225-27.04"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M206.258 56.246c3.969-7.805 11.635-9.584 17.042-6.298 8.22 4.985 38.635 43.965 34.267 78.511-.464 3.665-3.412 6.152-7.09 5.802-2.531-.241-6.035-.717-10.82-1.616-4.517-.822-8.35-3.628-10.677-7.53l-28.882-1.027s-4.928-3.423-.685-17.115c3.628-11.706-4.311-29.026 6.845-50.727z"
        className="fillBlack"
        fill="#000"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M222.752 78.152s6.228 7.051 6.023 23.208c-.137 8.146-3.559 18.757.342 26.972M196 117c.364-6.414 3.273-13.103 3.564-19.724.581-11.655-1.955-19.655-.064-28.276M23.928 181.798s9.924 19.237 14.989 36.283c5.065 17.046 1.232 25.808 2.19 35.598M197 196s27.594 25.613 65 7.552"
        stroke="#fff"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M230.813 206.132l-1.472-.124-.722 8.558 1.471.124.723-8.558z"
        fill="#fff"
        stroke="#fff"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(ShakeHandsIllustration);
