import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const AmbitionIllustration = (props: IIconProps) => {
  const { width = 321, height = 320, className } = props;

  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 321 320"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="#fff" d="M1 0h320v320H1z" />
      <path
        d="M206.711 45.91c-1.599.8-1.018 3.2.727 3.2h70.316c2.472 0 4.145-2.546 3.127-4.8 0 0 0-.073-.073-.073-2.254-4.58-10.544-.509-12.798-1.381-2.254-.873-4.726-12.071-15.197-12.58-10.544-.436-14.761 10.107-19.706 9.38-4.945-.727-6.835-2.4-10.544-1.236-4.654 1.454-5.453 7.199-8.58 6.472-2.618-.582-5.381.072-7.272 1.018z"
        fill="#fff"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.007 70.342H51.319c-1.018 0-1.455-1.236-.727-1.89.145-.146.29-.219.363-.364 1.818-1.454 4.581-1.454 5.454-1.018.29.145.581.145.872 0 1.6-1.018 5.09-4.145 10.617-3.127 3.563.655 4.29 2.69 4.29 4.29 0 1.236-1.018 2.11-2.181 2.11zM71.17 23.732s1.963 1.236 2.327 1.963c0 0 .29-1.963 2.4-3.854"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M110.945 241.732c-.654 25.596-1.454 59.336-1.454 59.336s-11.925 2.763-16.797 4.872h23.996s9.962-87.55 21.524-122.162c.509-1.527 2.617-1.454 3.126 0 4.8 15.27 20.433 66.534 33.522 122.089h26.905s-14.543-5.308-20.288-6.617c0 0-12.216-112.491-23.196-145.067 0 0-12.871 9.962-37.23 3.417.073 0-9.453 58.536-10.108 84.132z"
        className="fillBlack"
        fill="#000"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.663 159.127s-3.926-.509-6.472 1.818c-2.545 2.327 3.054 2.545 3.054 2.545s-4.29 3.418-5.017 6.108c-.727 2.691 2.327 2.546 2.327 2.546s-2.327 6.108 0 5.453c2.327-.582 5.236-3.49 5.236-3.49s-.146 5.017 2.69 3.054c2.836-1.963 2.836-12.362 2.836-12.362s36.648-47.701 55.118-59.481c0 0-.654 46.102-.654 48.938 0 2.835 3.344 3.417 3.344 3.417M158.356 154.183s1.527-1.091 2.4-3.127c.872-2.036-2.909-45.665-1.164-55.409M58.664 159.127S95.344 96.397 121.667 82"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M147 77.333s7.938 1.444 33.752 10.824c25.814 9.38 51.119 16.943 64.644 15.561 0 0 6.69-6.326 9.599-6.18 2.908.145-3.273 6.326-3.273 6.326s8.363.509 9.235 1.381c.873.873-4.944 2.4-4.944 2.4s3.926.873 3.272 2.545c-.655 1.745-8.217 1.164-8.217 1.164s4.581 1.89 3.054 2.908c-1.527 1.018-8.362.146-11.634-4.799 0 0-40.285 9.235-83.332-8.871M196.968 90.92s-7.854 9.235-6.472 23.051M135.523 74.341s.073-10.543 0-14.106c0 0-5.962-1.818-6.181-6.835-.218-5.018 4.422-3.466 6.181-.733M96.984 100.664s8.581 6.836 12.653 18.47M140.832 76.377l.835-8.377M68.407 163.054s-1.381-6.326-7.198-10.762"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.198 127.109s1.018-9.599 0-27.85c-1.018-18.252 2.69-22.615 2.69-22.615l3.273 1.818c-1.891 5.817 3.781 26.323 2.181 44.938 0 .073-.145 2.691-8.144 3.709z"
        className="fillBrown"
        fill="#C9B098"
        stroke="#C9B098"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125 76.25s7.321 8.2 16.521 6.434c9.2-1.766 7.321-7.948 7.321-7.948L146.25 73.6s-3.239 3.596-9.264 2.334c-6.026-1.325-8.228-2.145-11.986.316z"
        className="fillBrown"
        fill="#C9B098"
        stroke="#C9B098"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M123.667 136.12s.654-2.836.799-5.453l-.799 5.453z" fill="#C9B098" />
      <path
        d="M123.667 136.12s.654-2.836.799-5.453"
        stroke="#C9B098"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M127.743 135.229s.436-3.054.363-4.872l-.363 4.872z" fill="#C9B098" />
      <path
        d="M127.743 135.229s.436-3.054.363-4.872"
        stroke="#C9B098"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M132.03 134.423s.146-2.763-.363-5.09l.363 5.09z" fill="#C9B098" />
      <path
        d="M132.03 134.423s.146-2.763-.363-5.09"
        stroke="#C9B098"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M135.475 132.514s.146-2.036-.509-4.072l.509 4.072z" fill="#C9B098" />
      <path
        d="M135.475 132.514s.146-2.036-.509-4.072"
        stroke="#C9B098"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M239.579 99.937s-3.054 8.363-1.164 14.034M153.702 78.85l.945-1.673a6.525 6.525 0 0 1 6.327-3.126l50.609 6.544c3.127.436 5.454 2.981 5.6 6.108l.436 13.234"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M217.109 86.776v-.364c.219-3.636 3.345-6.471 6.981-6.471l84.132 1.308a4.27 4.27 0 0 1 4.29 4.145c.363 6.472 1.091 19.488 1.672 31.34.582 11.999-7.489 22.76-19.197 25.524-17.96 4.29-44.865 9.962-67.625 11.634-2.836.218-5.381-1.963-5.599-4.799l-2.981-37.23"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M160.755 151.056s26.178 6.035 56.282 3.636c2.909-.218 5.017-2.763 4.799-5.599l-1.018-13.162M246.801 139.202v-6.835l2.4 3.418 1.672-4.872 1.745 6.689M255.667 136v-6.667l4 5.461M263.041 135.057l-1.164-7.126s5.818-1.163 2.255 3.563l2.836 2.764M271.181 133.066L269 126.667M273.388 126.667l-3.055 3.199 6.618 1.236M284.832 124.223l1.381 5.962M288.249 123.496l2.981 5.744 1.382-6.326"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M209.911 145.602l22.833-6.689-1.236-1.673.581-2.036-2.036-1.163.8-1.818-1.818-.727 1.018-2.618-23.778 7.126 2.473 1.236-1.6 1.673 1.891.509-1.091 2.836h2.109l-.146 3.344zM92.985 173.307l7.635-22.615-2.181.146-1.237-1.673-2.108 1.018-1.091-1.672-1.6 1.018-1.6-2.327-7.78 23.56 2.472-1.309.437 2.254 1.527-1.236 1.672 2.545 1.163-1.745 2.69 2.036z"
        className="fillBlack"
        fill="#000"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.187 143.93l29.086 27.05c1.745 1.6 4.508 1.309 5.89-.654l5.09-7.272"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.692 166.617l5.526 5.963c1.309 1.381 1.527 3.49.509 5.163-4.944 8.216-20.214 33.303-36.503 54.9-12.58 16.724-35.121 22.323-54.172 13.743l-14.834-6.69c-3.054-1.382-4.363-5.09-2.764-8.071 9.09-17.307 37.594-70.898 56.646-96.566 1.236-1.673 3.417-2.109 5.235-1.164l7.708 4-6.835-5.745c-2.182-1.818-2.327-5.017-.437-7.053 8.726-9.162 31.413-32.867 43.193-43.92 1.818-1.673 4.654-1.673 6.399.072l2.981 2.982"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.578 208.792l2.254-1.527-10.47-3.49-5.018 9.162 11.998 4.072-.073-2.109 1.673-1.309-1.091-2.109 1.745-1.018-1.018-1.672z"
        className="fillBlack"
        fill="#000"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M133.923 50.782s-1.09-11.78-.291-15.343c.8-3.563 11.877-10.008 11.368-8.772 0 2-.969 2.882-.969 2.882s8.507-3.272 9.598-1.818c1.091 1.454-4.726 4.217-4.726 4.217s7.126-1.454 7.126.146c0 1.6-6.181 4.217-6.181 4.217s2.909 13.453 2.618 19.343h-3.272s0 11.343-3.927 12.507C141.34 69.324 139 63.333 139 63.333"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M143.885 52.018c1.928 0 3.491-1.92 3.491-4.29s-1.563-4.29-3.491-4.29c-1.927 0-3.49 1.92-3.49 4.29s1.563 4.29 3.49 4.29zM153.702 42.928a2.98 2.98 0 0 0-2.254 1.019c.363 1.963.872 4.871 1.018 7.271.363.218.8.29 1.236.29 1.963 0 3.49-1.89 3.49-4.29 0-2.399-1.599-4.29-3.49-4.29z"
        fill="#fff"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M141.486 58.271C142.333 60 145 62 148.334 61.333M68.407 305.94h212.765M34.334 20.571s4.892 3.048 5.907 4.762c0 0 .739-4.762 6.093-9.333"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(AmbitionIllustration);
