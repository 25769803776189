import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const HeroTableIllustration = (props: IIconProps) => {
  const { width = 480, height = 360, className } = props;

  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 480 360"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M26.28 211.916c-2.13 3.912-6.786 3.755-6.707 2.738.078-1.017 2.604-2.659 2.604-2.659s-14.995.078-15.153-10.405c-.237-10.482 11.523-10.638 16.1-10.169 0 0-3.63-1.643-2.525-3.129 1.104-1.486 4.814.939 5.682 3.598M31.095 198.07s1.342-.078 2.131 1.174c.79 1.251-.473 3.207-2.13 3.833"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M42.302 201.669s-1.815 10.326-16.021 10.326c0 0 5.209-3.52 5.84-9.466 0 0 1.5-.782 1.263-2.346-.158-1.487-2.289-2.113-2.289-2.113s-1.026-3.676-4.814-6.101c0 0 13.259-1.721 16.02 9.7z"
          className="fillBlack"
          fill="#000"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M25.57 199.948s-.868-1.721-1.183-4.694l1.183 4.694z" fill="#000" />
        <path
          d="M25.57 199.948s-.868-1.721-1.183-4.694"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M25.886 204.094s-.868 1.564-1.183 4.85l1.183-4.85z" fill="#000" />
        <path
          d="M25.886 204.094s-.868 1.564-1.183 4.85M10.812 193.69s3.473-42.243 21.703-50.535c18.231-8.292 17.126 30.587 21.23 41.069 0 0 14.837 5.007 26.36 6.337M11.76 209.413s2.051 36.141 17.125 50.926c14.995 14.706 18.862-8.527 23.045-29.805 0 0 17.126-.625 28.333-1.877M19.573 214.654s5.13 14.707 16.02 25.346M17.52 191.499s4.973-17.053 19.336-33.09M31.805 164.511s4.578 28.084-.947 70.248M10.812 178.514s8.602 2.816 11.286 6.023M11.76 174.994s9.628 3.051 12.548 6.962M51.22 206.519s-5.05-4.929-9.865-7.588M50.115 199.635s-3.63 5.241-6.077 7.353"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.205 50.222c1.736-1.8 3.867-3.13 6.234-4.146 6.393-2.66 20.047-6.258 31.964 3.911 16.179 13.846 11.917 38.8-12.391 44.511-24.308 5.711-32.516-13.298-33.62-21.2-.948-7.04.315-15.488 7.813-23.076z"
          className="fillBlack"
          fill="#000"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.7 68.527s-7.814-27.38 13.653-41.538c-.079-.079 16.021 25.423-13.653 41.538zM33.7 68.527S25.886 41.773 10.89 36.454c0 0-1.341 23.937 22.809 32.073zM35.514 112.177S19.02 91.291 33.62 68.527c.08 0 16.732 13.064 1.895 43.65z"
          fill="#fff"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M.789 102.321s20.835.078 31.963-32.308c0 0-25.808 4.85-31.963 32.308z"
          fill="#fff"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.104 74.08s11.444 6.963 29.517-5.553c.078 0-16.258-4.85-29.517 5.554z"
          fill="#fff"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M70.398 68.527s-15.705-12.36-36.777 0c.078 0 20.046 12.282 36.777 0zM46.563 83.077s1.658 9.779 8.84 11.421c-.08 0-.79-9.622-8.84-11.42z"
          fill="#fff"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M171.022 104.198s13.259-2.112 15.232-12.75c0 0 .711 4.615 2.841 7.04 0 0 5.051-10.17-4.34-16.35 0 0 7.024-8.526-.553-10.247-6.945-1.565-6.945 6.805-3.472 10.09.079-.077-16.732.314-9.708 22.217z"
          className="fillBlack"
          fill="#000"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M172.601 106.701s13.101-2.112 13.654-15.254c0 0-.632 8.292 2.92 11.969"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M173.706 102.79s1.184 4.381 5.525 6.415c1.578.704 3.551.625 4.893-.47.947-.704 1.973-1.877 2.999-3.755M169.286 94.733s-8.208 7.275-11.838 21.278c-3.63 14.002-4.025 34.732 8.997 55.697 0 0-1.815 8.527 4.499 12.438.631.391 1.499.157 1.657-.547.316-1.565 0-3.912 0-5.085 0 0 1.026 3.677 2.447 4.459 1.42.782-1.184-9.466-1.184-9.466s2.052 1.721 3.63 1.565c1.579-.157-3.63-4.224-5.84-7.041-2.21-2.816-10.733-25.267-1.5-51.003M190.28 90.274s6.155.313 9.076 3.52c2.92 3.207 15.626 24.016 16.731 35.046 1.105 11.108-17.363 25.893-17.363 25.893s-1.578 10.091-5.524 11.577l1.499-4.38s-3.709 4.459-5.603 5.006c-1.894.47 1.341-5.006 1.341-5.006s-3.077 1.877-4.34 2.581c0 0 .789-3.129 5.051-8.214 0 0-4.262-.313-5.051-.86-.789-.626 7.261-1.486 8.681-2.503 1.421-1.017 10.418-17.523 9.392-24.955-1.026-7.51-7.182-15.332-7.182-15.332M196.909 108.188s1.578 12.595-9.155 23.077M159.342 156.923s6.156-2.19 8.761-4.694M199.908 144.016s2.604 2.738 6.471 3.598M169.839 164.433l17.994-3.599"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M198.251 158.722l6.155-1.252c1.5-.312 2.92.626 3.236 2.034l6.551 27.145c.394 1.486-.553 2.973-2.052 3.286l-44.038 9.856c-1.5.313-2.921-.547-3.315-2.034l-6.945-28.005c-.395-1.486.631-3.051 2.131-3.364l3.156-.626"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M167.155 180.469c-.868.235-1.42 1.095-1.184 1.956l3.947 17.132 40.644-9.153-4.814-17.992c-.237-.939-1.105-1.487-2.052-1.252l-25.965 6.024"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M162.657 207.253l-5.446 5.789c-.552.625 0 1.642.868 1.408l70.635-15.802c.868-.157.947-1.408.079-1.643l-9.076-2.894c-1.342-.391-2.841-.47-4.183-.157l-48.773 10.952c-1.579.391-2.999 1.173-4.104 2.347z"
          className="fillBlack"
          fill="#000"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M133.769 170.709v73.162M133.768 232.258H99.273a4.167 4.167 0 0 1-4.167-4.176v-41.583a4.167 4.167 0 0 1 4.167-4.176h34.495v49.935z"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M133.769 226.452h-15.908c-1.548 0-2.838-1.303-2.838-2.865v-31.431c0-1.563 1.29-2.865 2.838-2.865h15.908v37.161zM95.106 197.419H106.3c1.562 0 2.864 1.305 2.864 2.87v14.002c0 1.566-1.302 2.87-2.864 2.87H95.106v-19.742zM238.214 174.706c5.31 0 9.614-4.267 9.614-9.53 0-5.263-4.304-9.529-9.614-9.529-5.31 0-9.614 4.266-9.614 9.529s4.304 9.53 9.614 9.53zM232.502 157.001l-1.183-1.877c-.395-.626-.237-1.408.394-1.878.632-.469 1.579-.234 1.973.391l1.184 1.878"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M202.67 273.637s4.499-2.816 12.075-1.564c1.894.313 3.078 2.034 2.762 3.911-.868 4.694-4.104 13.221-16.652 14.394-1.5.156-2.999 0-4.341-.469-2.525-.939-5.682-3.442-4.972-9.857.631-5.241 5.367-8.761 8.997-10.717 2.131-1.095 4.341 1.33 2.999 3.286-.158.312-.473.625-.868 1.016z"
          className="fillBlack"
          fill="#000"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M204.88 268.631s7.103-2.19 9.707 3.442M191.542 239.609s-5.446 3.676-9.865 8.292c-4.42 4.615-11.365 11.421-11.128 17.757.079 2.66 3.078 19.635 15.626 19.635 17.758 0 32.437 3.364 46.169-3.676 13.733-7.041 34.015-23.39 36.778-27.223 0 0 2.762-6.102 3.867-7.667 1.105-1.564 0 3.208 0 3.208s7.261-5.241 8.208-4.146c.947 1.095-2.763 3.52-2.763 3.52s5.999-1.408 5.367.156c-.631 1.565-3.867 3.521-3.867 3.521s1.973.625 1.263 1.721c-.79 1.095-5.13 5.084-10.023 5.319 0 0-20.204 32.621-42.776 41.304-22.571 8.761-54.534 9.935-64.715-8.996-10.181-18.931-5.998-30.431-1.263-36.61 4.814-6.102 17.599-13.455 17.599-13.455s2.763-8.527 7.735-11.422c4.972-2.972.947 8.058.947 8.058s4.814-.704 2.841.704z"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M198.329 298.044c4.446 0 8.05-2.907 8.05-6.493 0-3.585-3.604-6.492-8.05-6.492-4.446 0-8.05 2.907-8.05 6.492 0 3.586 3.604 6.493 8.05 6.493zM209.773 227.718l-3.394 34.889 30.306 1.252 3.078-34.576-28.648-1.408c-2.21-.079-3.867-2.113-3.552-4.303 0-.078 0-.156.079-.313.316-1.877 2.131-3.129 4.104-2.972 7.892.704 28.649 2.112 31.016 2.581 3.157.548 1.263 6.962-1.578 4.772-1.105-.861-.474-2.738.473-2.816M227.057 252.829l21.466-16.115"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M378.349 271.839s10.733-3.755 18.862.391c0 0-2.288-4.694-1.894-8.371 0 0 7.577 3.833 10.102 11.734 2.447 7.901-2.367 11.656-2.367 11.656 2.13 3.129 12.864 2.503 13.653 11.265.473 5.163-.868 7.353-.789 10.952 0 0-3.631-3.208-3.552-8.449 0 0-2.683 5.633 3.315 14.394 0 0-12.706-3.129-13.18-14.941-.473-11.813-1.578-11.187-1.578-11.187s-11.128 7.979-17.758-9.856c0 0 2.684.234 9.787-1.8 0 .079-9.471-.547-14.601-5.788z"
          className="fillBlack"
          fill="#000"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M376.929 274.811s7.497 4.694 16.021 2.973M404.946 313.612s-4.341-2.895-3.946-12.438c.394-9.544.789-9.231-.079-11.735M383.716 272.073s-.553-3.598 1.184-6.023c1.815-2.425 8.444-1.017 11.838 1.799M341.256 268.475s21.941 1.173 44.039 15.723"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M388.53 308.214c-24.702-3.677-49.72-32.152-49.72-37.002 0-4.85 2.446-5.788 2.446-5.788 10.339.704 17.994 6.649 17.994 6.649s14.206-12.047 30.385-15.02c0 0 9.944 1.565 14.679-4.224"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M396.817 254.472s8.286-1.721 11.364-5.632c3.157-3.912-6.155-4.068-9.944-2.582 0 0 3.236-2.581 3.631-3.129.394-.547-7.182 1.643-15.232 7.979 0 0-30.78 5.007-44.038 14.394M377.639 305.163s5.761 11.186 17.284 12.829c11.522 1.643 22.966-5.632 28.412-11.186 5.445-5.554 1.499-24.094-2.999-33.794-4.499-9.7-12.075-24.094-12.075-24.094M398.316 256.506s1.658 7.119 4.657 14.159M394.765 245.945c-7.498-14.002-7.34-23.859-11.681-31.916"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M384.9 209.883l-5.288-23.39c-.316-1.408-1.894-2.19-3.236-1.643l-10.102 4.381c-1.105.469-1.657 1.721-1.263 2.816l7.498 22.92c.473 1.33 1.973 2.034 3.315 1.33l7.892-3.911c.868-.391 1.42-1.408 1.184-2.503z"
          className="fillBlack"
          fill="#000"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M386.005 211.447s-4.736-4.146-3.552-10.326c0 0-3.709 2.034-4.814.783-1.5-1.643 3.551-4.303 5.998-4.303 4.025 0 5.603 13.455 5.603 13.455s11.444 22.529 16.653 34.576"
          fill="#fff"
        />
        <path
          d="M386.005 211.447s-4.736-4.146-3.552-10.326c0 0-3.709 2.034-4.814.783-1.5-1.643 3.551-4.303 5.998-4.303 4.025 0 5.603 13.455 5.603 13.455s11.444 22.529 16.653 34.576M367.774 200.73s-1.421.235-2.921 1.173c-1.499.939 2.921 2.269 2.921 2.269s-1.263 1.564-1.579 3.051c-.316 1.564 2.92 1.564 2.92 1.564s-1.184 3.208 2.447 3.442M307.004 233.273l15.39 26.753c1.105 1.878 3.552 2.504 5.446 1.408l37.014-22.842c1.736-1.095 2.367-3.364 1.341-5.163l-14.205-25.893c-1.026-1.956-3.473-2.581-5.446-1.486l-38.198 21.981c-1.736 1.017-2.368 3.442-1.342 5.242z"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M337.152 237.184c1.918 0 3.473-1.541 3.473-3.442 0-1.901-1.555-3.442-3.473-3.442s-3.472 1.541-3.472 3.442c0 1.901 1.554 3.442 3.472 3.442z"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M422.624 12.517c.789-.705 1.973 0 1.894 1.016-.316 2.738-.631 6.259 1.105 7.588 2.21 1.565 5.682-.704 8.208-2.972 1.263-1.095 3.157-.313 3.236 1.33.078 1.564.315 2.894.71 2.503 2.21-2.034 6.314-7.823 5.13-13.69-1.184-5.867-7.813-9.935-14.364-5.397-6.393 4.38-7.892 11.343-7.892 11.343l1.973-1.721z"
          className="fillBlack"
          fill="#000"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M422.703 10.013s.552-2.894-1.263-1.877c-1.815 1.016-2.604 4.693-.71 5.867 0 0-3.552 11.655 2.446 14.863 4.736 2.581 14.601-7.04 14.601-7.04s-.316 1.564 1.973 1.094c2.289-.469 4.42-5.71.552-4.928"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M437.619 71.03c8.997-7.744 19.494-31.604 17.442-42.555-2.052-10.952-12.943-13.612-12.943-13.612M434.541 64.459s3.946-8.37 4.893-27.38"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M395.243 49.765c4.72 0 8.546-3.793 8.546-8.47 0-4.679-3.826-8.471-8.546-8.471-4.72 0-8.546 3.792-8.546 8.47 0 4.678 3.826 8.47 8.546 8.47z"
          fill="#fff"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M395.243 45.53c2.36 0 4.273-1.897 4.273-4.236 0-2.34-1.913-4.235-4.273-4.235-2.36 0-4.273 1.896-4.273 4.235 0 2.339 1.913 4.235 4.273 4.235zM238.214 170.471c2.95 0 5.341-2.371 5.341-5.295 0-2.923-2.391-5.294-5.341-5.294s-5.341 2.371-5.341 5.294c0 2.924 2.391 5.295 5.341 5.295z"
          className="fillBrown"
          fill="#C9B098"
          stroke="#C9B098"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M421.519 8.135c-16.731-1.799-33.147 7.588-35.593 16.037-2.052 7.04 2.525 11.656 2.525 11.656s-1.105-6.258 4.025-7.51c5.13-1.252 5.438 3.976 5.438 3.976"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M435.567 61.72c-2.841 1.409-22.177-1.172-28.254-12.124-6.077-11.03.632-27.77.632-27.77M400.842 27.849s-1.5-5.007-4.815-6.65c-3.314-1.642 4.578 4.068 5.998 4.381 1.421.235-.157-3.442-1.262-5.788"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M407.313 49.596s-12.312 17.053-4.735 50.3c0 0-9.707 9.934-10.576 12.516 0 0 11.76-4.537 15.311-9.544 0 0 5.84-28.71 10.891-36.845 0 0 1.184 18.462 12.943 59.296 2.052 6.493 4.42 11.343 8.366 14.238 0 0-.868-10.561-3.157-14.394-.158-24.485 3.157-38.41 2.052-51.786 0 0-3.551-4.772-4.735-11.265-5.446 0-22.098-3.05-26.36-12.516z"
          className="fillBlack"
          fill="#000"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M298.56 131.343c0-8.136-.868-16.271-2.604-24.172l-3.552-16.506c-.71-3.285 1.657-6.415 4.814-6.415h57.692c3.078 0 5.445 3.051 4.814 6.259l-3.078 16.897a112.717 112.717 0 0 0-1.894 20.573v4.068M297.771 117.653s-9.786-.625-10.576 13.847M298.007 120.078s-4.419 1.565-3.946 11.421M355.304 117.653s9.787-.625 10.576 13.847M354.988 120.078s4.42 1.565 3.946 11.421M431.7 172.96l4.341.078c7.655 0 14.127-.861 21.624-2.582l15.548-3.52c3.078-.704 6.077 1.643 6.077 4.772v57.184c0 3.051-2.841 5.397-5.84 4.772l-15.864-3.051a100.564 100.564 0 0 0-19.335-1.878h-3.789M447.721 172.177s1.737-10.404-16.021-10.404M445.511 172.412s-2.525-3.755-13.811-3.755M447.721 229.205s.553 9.7-13.022 10.482M445.511 228.97s-1.421 4.381-10.733 3.912M425.939 293.742c4.814 5.867 5.998 6.336 11.996 11.03l12.548 9.7c2.526 1.955 2.526 5.71.079 7.666l-44.906 35.984c-2.447 1.956-5.998 1.174-7.419-1.486l-7.576-14.159c-2.999-5.711-6.63-11.108-10.655-16.115l-17.204-22.451c-1.579-2.034-1.342-5.006.552-6.806"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M385.61 334.107s-4.893 7.979-13.022.704a17.6 17.6 0 0 1-2.526-2.895l-12.39-17.21c-4.972-6.805 5.13-10.795 5.13-10.795"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M384.426 332.151s-4.341 1.643-9.786-5.789l-12.864-16.897s-1.658-3.364 2.288-3.989M210.562 306.258c.395 4.303.711 8.996 2.368 16.428l3.552 15.41c.71 3.051-1.658 6.024-4.815 6.024h-57.691c-3.078 0-5.446-2.816-4.814-5.789l3.078-15.723a98.088 98.088 0 0 0 1.894-19.166l-.237-10.17M51.22 171.239c4.814.626 9.708.938 14.522.938l14.442-.234M80.105 236.167c-11.759 0-21.782.469-29.595 1.486M211.194 312.829s7.813.47 10.102-9.074M210.957 310.56s2.526-.782 3.551-5.084M153.66 312.829s-12.075-1.329-10.576-19.713M153.976 310.717s-3.946-3.911-3.946-17.601M211.825 115.15c.316-3.208.552-6.337 1.184-9.466l3.078-15.723c.631-3.051-1.736-5.79-4.814-5.79h-57.692c-3.157 0-5.524 2.895-4.814 6.024l3.551 15.411c1.737 7.353 2.92 16.115 2.92 25.971M154.134 115.306s-10.576 1.252-10.576 15.958M154.449 117.575s-4.419 4.537-3.946 13.69M222.321 131.656c0-15.724-10.417-16.506-10.417-16.506"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M155.239 131.499l-71.424-.234c-2.052 0-3.71 1.642-3.71 3.598-.079 21.669 0 132.986 0 154.576 0 2.034 1.658 3.599 3.71 3.599h80.263M204.091 131.578l-37.567-.078M425.702 293.116h7.497c2.131 0 3.789-1.721 3.71-3.833l-6.866-153.638c-.079-1.955-1.737-3.442-3.631-3.442l-210.246-.547M242.21 293.116h115.699"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M195.567 120.391s5.604 1.486 7.577 11.187l-15.311-.079c0 .079 5.919-5.319 7.734-11.108z"
          className="fillBlack"
          fill="#000"
          stroke="#000"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h480v360H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default React.memo(HeroTableIllustration);
