import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
  rotate?: number;
}

const CrossIcon = (props: IIconProps) => {
  const { width = 24, height = 24, className, rotate = 0 } = props;

  return (
    <svg
      width={width}
      height={height}
      className={className}
      style={{ transform: `rotate(${rotate}deg)` }}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 5v14M5 12h14"
        stroke="#C9B098"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(CrossIcon);
