import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const PlayIcon = (props: IIconProps) => {
  const { width = 24, height = 24, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 21.6807V3.68066L21 12.6807L6 21.6807Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default React.memo(PlayIcon);
