import React, { useState } from 'react';

import { PrimaryButton } from '../components/Buttons/PrimaryButton';
import { ContentAnimate } from '../components/ContentAnimate/ContentAnimate';
import Layout from '../components/layout';
import SEO from '../components/seo';
import AmbitionIllustrationImage from '../images/Company/ambition-illustration';
import ethosDotsMImage from '../images/Company/ethos-dots-m.svg';
import ethosDotsImage from '../images/Company/ethos-dots.svg';
import HeroTableIllustrationImage from '../images/Company/hero-table-illustration';
import HugsIllustrationImage from '../images/Company/hugs-illustration';
import ShakeHandsIllustrationImage from '../images/Company/shake-hands-illustration';
import TalentIllustrationImage from '../images/Company/talent-illustration';
import CrossIcon from '../images/Cross';
import PlayIcon from '../images/Index/Play';

import style from '../styles/pages/company.scss';

import cn from 'classnames';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const CompanyPage = (props: any) => {
  const { data } = props;

  const handleJoinUs = () => {
    window.open('https://trend-capital-holdings-inc.hirehive.com', '_blank');
  };

  const [isVideoPlaying, setVideoPlaying] = useState(false);

  const playVideo = () => {
    if (!isVideoPlaying) {
      setVideoPlaying(true);
    }
  };

  // const handleModalVideoClose = useCallback(() => {
  //   if (isVideoPlaying) {
  //     setVideoPlaying(false);
  //   }
  // }, [isVideoPlaying]);

  return (
    <Layout>
      <SEO title="Company" />
      <section className={style.hero}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2">
              <ContentAnimate inViewAnimationClass={style.heroInView}>
                <h1>
                  At the heart of
                  <br /> everything <br />
                  is our talented team
                </h1>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.illustrationInView}>
                <HeroTableIllustrationImage className={style.illustrationImage} />
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.heroInView}>
                <p>
                  Trend is the exact opposite of an assembly line. Each member plays a crucial
                  creative role contributing to the process and the product we are creating.
                </p>
              </ContentAnimate>
            </div>
          </div>
        </div>
      </section>

      {isVideoPlaying ? (
        <video width="100%" controls autoPlay>
          <source src="../video/trend_capital_culture.mp4" type="video/mp4" />
          Your browser does not support HTML video.
        </video>
      ) : (
        <section className={style.bigImageWrapper}>
          <Img
            className={cn('lazy', style.bigImage)}
            fluid={data.bigImage.childImageSharp.fluid}
            alt="code"
            loading="eager"
          />

          <ContentAnimate inViewAnimationClass={style.heroInView}>
            <PrimaryButton className={style.playVideo} onClick={playVideo}>
              <PlayIcon />
            </PrimaryButton>
          </ContentAnimate>
        </section>
      )}

      <section className={style.family}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-3 offset-lg-2">
              <ContentAnimate inViewAnimationClass={style.familyInView}>
                <h2>We're obsessed with growth</h2>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.familyInView}>
                <p>
                  Every team member is expected to be self driven, never settling for the status quo
                  and always be growing faster than the company itself.
                </p>
              </ContentAnimate>
            </div>
            <div className="col-12 col-md-6 offset-lg-1">
              <ContentAnimate inViewAnimationClass={style.illustrationInView}>
                <HugsIllustrationImage
                  className={cn(style.illustrationImage, style.illustrationHugsImage)}
                />
              </ContentAnimate>
            </div>
          </div>
        </div>
      </section>

      <section className={style.benefits}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ContentAnimate inViewAnimationClass={style.benefitsInView}>
                <Img
                  className={cn('lazy', style.familyImage)}
                  fluid={data.familyImage.childImageSharp.fluid}
                  alt="Men Image"
                  loading="eager"
                />
              </ContentAnimate>
            </div>
            <div className="col-12 col-md-6 col-lg-5 offset-lg-1">
              <ContentAnimate inViewAnimationClass={style.benefitsInView}>
                <h2>We take care of our own</h2>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.benefitsInView}>
                <p>
                  Because our team is at the heart of our organization, we ensure that the working
                  enviroment is pleasant and each member is well taken care of.
                </p>
              </ContentAnimate>
            </div>
            <div className="col-12 col-md-6 col-lg-5 offset-lg-1">
              <ul>
                <ContentAnimate inViewAnimationClass={style.benefitsInView}>
                  <li>
                    <CrossIcon />
                    Health, dental &amp; vision coverage
                  </li>
                </ContentAnimate>
                <ContentAnimate inViewAnimationClass={style.benefitsInView}>
                  <li>
                    <CrossIcon />
                    Top-notch equipment
                  </li>
                </ContentAnimate>
                <ContentAnimate inViewAnimationClass={style.benefitsInView}>
                  <li>
                    <CrossIcon />
                    Career growth opportunities
                  </li>
                </ContentAnimate>
                <ContentAnimate inViewAnimationClass={style.benefitsInView}>
                  <li>
                    <CrossIcon />
                    Daily catered lunches
                  </li>
                </ContentAnimate>
                <ContentAnimate inViewAnimationClass={style.benefitsInView}>
                  <li>
                    <CrossIcon />
                    Flexible schedules
                  </li>
                </ContentAnimate>
                <ContentAnimate inViewAnimationClass={style.benefitsInView}>
                  <li>
                    <CrossIcon />
                    Kombucha on tap
                  </li>
                </ContentAnimate>
                <ContentAnimate inViewAnimationClass={style.benefitsInView}>
                  <li>
                    <CrossIcon />
                    Excellent local coffee
                  </li>
                </ContentAnimate>
                <ContentAnimate inViewAnimationClass={style.benefitsInView}>
                  <li>
                    <CrossIcon />
                    Pool, ping-pong, PS4, etc.
                  </li>
                </ContentAnimate>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className={style.hire}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4 offset-lg-1">
              <ContentAnimate
                className={style.centered}
                inViewAnimationClass={style.illustrationInView}
              >
                <TalentIllustrationImage className={style.illustrationImage} />
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.hireInView}>
                <h3>We hire for talent</h3>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.hireInView}>
                <p>Top performers don’t want to work alongside B-players.</p>
              </ContentAnimate>
            </div>
            <div className="col-12 col-md-6 col-lg-4 offset-lg-2">
              <ContentAnimate
                className={style.centered}
                inViewAnimationClass={style.illustrationInView}
              >
                <AmbitionIllustrationImage className={style.illustrationImage} />
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.hireInView}>
                <h3>We hire for ambition</h3>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.hireInView}>
                <p>Our team is driven to achieve results, to grow, and to evolve.</p>
              </ContentAnimate>
            </div>
          </div>
        </div>
      </section>

      <section className={style.positions}>
        <Img
          className={cn('lazy d-lg-none', style.ourEthosBGImage)}
          fluid={data.ourEthosBG.childImageSharp.fluid}
          alt="Men Image"
          loading="eager"
        />
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8 offset-md-2 col-lg-4 offset-lg-0">
              <ContentAnimate inViewAnimationClass={style.positionsInView}>
                <h2>Our ethos...</h2>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.positionsInView}>
                <p>
                  Based in the beautiful PNW, we're a team of hard workers, a culture of symbiosis,
                  and a company we can all be proud of.
                </p>
              </ContentAnimate>
            </div>
            <img
              className={cn('d-none d-md-block', style.dotsIcon)}
              src={ethosDotsImage}
              alt="Dots Image"
            />
            <img
              className={cn('d-md-none', style.dotsIcon)}
              src={ethosDotsMImage}
              alt="Dots Image"
            />
          </div>
        </div>
      </section>

      <section className={style.workWithUS}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
              <ContentAnimate inViewAnimationClass={style.illustrationInView}>
                <ShakeHandsIllustrationImage className={style.illustrationImage} />
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.workWithUSInView}>
                <h2>Let's talk</h2>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.workWithUSInView}>
                <p>Explore open positions at Trend to see how we can work together.</p>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.workWithUSInView}>
                <PrimaryButton onClick={handleJoinUs}>Join Us</PrimaryButton>
              </ContentAnimate>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
const DefaultLayoutSettingsQuery = (props: any) => {
  const renderDefaultLayoutSettings = (data: any) => <CompanyPage data={data} {...props} />;

  return (
    <StaticQuery
      query={graphql`
        query CompanySection {
          bigImage: file(relativePath: { eq: "Company/big-image.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          familyImage: file(relativePath: { eq: "Company/family-image.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 946) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          ourEthosBG: file(relativePath: { eq: "Company/ourEthosBG.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1080) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={renderDefaultLayoutSettings}
    />
  );
};

export default DefaultLayoutSettingsQuery;
