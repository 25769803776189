import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const HugsIllustration = (props: IIconProps) => {
  const { width = 405, height = 503, className } = props;

  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 405 503"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M78.762 372.497c3.32-31.064 15.958-33.283 14.137-58.134-2.035-27.514-10.496-34.614-7.39-53.253 3.106-18.638 1.071-34.614 1.071-34.614s-22.17 30.176-13.709 60.353c8.461 30.177 6.32 43.49 4.177 59.466-2.142 15.975-2.035 26.182-2.035 26.182h3.749z"
        fill="#F2F2F2"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.28 374.716s-4.927-9.852-16.708-23.875c-11.78-14.023 0-17.218-3.962-33.105-3.963-15.887-14.352-30.532-9.21-38.165 5.14-7.633 1.284-16.508 1.284-23.52 0-7.011 5.355-12.692 5.355-12.692s15.637 21.035 11.781 37.544c-7.818 33.105 7.819 47.75 11.781 71.27 1.286 7.81 2.892 22.543 2.892 22.543"
        fill="#F2F2F2"
      />
      <path
        d="M32.28 374.716s-4.927-9.852-16.708-23.875c-11.78-14.023 0-17.218-3.962-33.105-3.963-15.887-14.352-30.532-9.21-38.165 5.14-7.633 1.284-16.508 1.284-23.52 0-7.011 5.355-12.692 5.355-12.692s15.637 21.035 11.781 37.544c-7.818 33.105 7.819 47.75 11.781 71.27 1.286 7.81 2.892 22.543 2.892 22.543"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.598 372.053s-9.853-43.933-8.782-72.335c1.07-28.401 12.852-42.602 10.71-63.903-2.142-21.301 2.142-39.94 2.142-39.94 6.426 22.189 18.207 35.502 10.71 72.779-6.212 31.064-17.136 45.265-13.923 63.016 3.213 17.751 1.5 40.383 1.5 40.383"
        fill="#F2F2F2"
      />
      <path
        d="M41.598 372.053s-9.853-43.933-8.782-72.335c1.07-28.401 12.852-42.602 10.71-63.903-2.142-21.301 2.142-39.94 2.142-39.94 6.426 22.189 18.207 35.502 10.71 72.779-6.212 31.064-17.136 45.265-13.923 63.016 3.213 17.751 1.5 40.383 1.5 40.383"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.769 372.053s-5.355-33.283-4.284-58.134c1.07-24.851-7.497-39.052-6.426-55.028 1.07-15.976 13.923-31.064 16.065-38.164C68.266 213.626 76.834 187 76.834 187s12.852 34.614-2.142 68.341-.214 44.377-1.071 70.116c-.643 15.71-8.782 26.272-7.712 46.596"
        fill="#F2F2F2"
      />
      <path
        d="M60.769 372.053s-5.355-33.283-4.284-58.134c1.07-24.851-7.497-39.052-6.426-55.028 1.07-15.976 13.923-31.064 16.065-38.164C68.266 213.626 76.834 187 76.834 187s12.852 34.614-2.142 68.341-.214 44.377-1.071 70.116c-.643 15.71-8.782 26.272-7.712 46.596"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.922 372.497c-3.32-25.561-15.958-27.425-14.138-47.928 2.035-22.632 10.496-28.49 7.39-43.844-3.106-15.355-1.07-28.491-1.07-28.491s22.17 24.852 13.708 49.703c-8.46 24.851-6.319 35.857-4.177 48.993 2.142 13.135 2.035 21.567 2.035 21.567h-3.748zM49.952 372.053s-3.106-45.708.107-62.572c3.213-16.863 11.78-27.513 11.78-27.513s2.143 37.276 0 59.465c-2.141 22.189-9.21 31.064-9.21 31.064l-2.677-.444z"
        fill="#F2F2F2"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 371.604S19.254 462.828 32.867 491h38.266S93 412.655 93 371.604H11z"
        fill="#F2F2F2"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.216 391.224l5.355-2.485c2.142-.976 4.82-.799 6.748.444l5.355 3.373c1.928 1.242 4.712 1.42 6.854.355l6.105-2.929a7.328 7.328 0 0 1 6.105 0l9.639 4.438c2.249 1.065 5.033.798 6.961-.533l4.391-3.018c1.928-1.331 4.82-1.597 7.069-.532l5.57 2.662c2.034.977 4.712.888 6.64-.266l1.927-1.154"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        stroke="#000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4 491h323"
      />
      <path
        d="M212 450c1.811 14.495 3.953 27.671 7.329 42.331.659 2.8 2.8 4.941 5.436 5.27 5.764.824 17.13 2.389 33.601 4.283 1.153.165 1.564-1.647.494-2.059-6.918-2.965-17.295-7.494-23.224-10.294a3.531 3.531 0 0 1-1.895-2.636c-.741-4.694-1.729-14.988-3.623-35.989"
        fill="#F2F2F2"
      />
      <path
        d="M212 450c1.811 14.495 3.953 27.671 7.329 42.331.659 2.8 2.8 4.941 5.436 5.27 5.764.824 17.13 2.389 33.601 4.283 1.153.165 1.564-1.647.494-2.059-6.918-2.965-17.295-7.494-23.224-10.294a3.531 3.531 0 0 1-1.895-2.636c-.741-4.694-1.729-14.988-3.623-35.989"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M236.967 449.099s-5.106-123.451 5.601-174.511c.247-1.071 1.811-1.071 1.976.082 3.953 25.448 19.683 105.168 56.578 103.768 36.237-1.318 64.238-65.555 64.238-65.555s-3.295-10.706-18.942-17.13c0 0-32.778 51.637-41.178 36.072-17.294-32.119-8.235-94.709-42.001-136.71h-49.413c-4.942 26.354-26.354 68.767-21.413 144.451 4.118 63.085 13.177 109.615 13.177 109.615s10.789 6.918 31.377-.082z"
        className="fillBrown"
        fill="#C9B098"
        stroke="#C9B098"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M204.216 105.347s-2.461 63.416 7.385 84.026c6.482 23.463 51.282 21.799 58.257 2.378 8.205-22.988 8.205-84.818 8.205-84.818l-73.847-1.586z"
        className="fillBlack"
        fill="#000"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M192.495 93.735s-10.706-14-3.294-26.353c7.412-12.354 17.295-8.73 21.413-14.825 3.294-4.94 0-13.176 5.765-21.412 4.035-5.765 9.882-4.941 12.353-9.883 2.471-4.94.659-15.565 16.471-18.941 11.53-2.471 28.989 9.388 28.001 33.765-.824 19.766-9.059 23.06-.824 31.296 7.001 7 11.53 11.53 4.942 21.412"
        fill="#F2F2F2"
      />
      <path
        d="M192.495 93.735s-10.706-14-3.294-26.353c7.412-12.354 17.295-8.73 21.413-14.825 3.294-4.94 0-13.176 5.765-21.412 4.035-5.765 9.882-4.941 12.353-9.883 2.471-4.94.659-15.565 16.471-18.941 11.53-2.471 28.989 9.388 28.001 33.765-.824 19.766-9.059 23.06-.824 31.296 7.001 7 11.53 11.53 4.942 21.412"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M253.438 34.44s3.295-4.942 6.589-1.648c3.294 3.294-.824 9.883-4.118 9.883-1.812 0-2.471-1.647-2.471-1.647-4.117 8.235-13.176 19.765-21.412 14.824-8.483-5.106-2.471-36.237 4.118-42.825 5.765 0 3.623 9.47 8.565 16.06 2.059 2.717 4.859 4.858 8.729 5.352z"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M347.736 108.23c-21.742-14.165-54.19-23.554-88.697-26.848-2.965-.247-5.518-2.47-6.177-5.353-.906-3.87-.577-7.906-.247-10.542.165-1.317-.988-2.388-2.306-2.059-5.271 1.4-9.636.742-12.271 0-1.318-.329-2.553.66-2.471 2.06.165 3.788-.411 7-1.153 9.635-.823 2.965-3.623 5.024-6.67 5.024-58.061.74-114.804 19.188-130.452 61.437-21.165 56.99 21.331 94.05 38.708 106.321 3.706 2.635 8.894 1.564 11.282-2.306 1.977-3.212 1.4-7.248-1.235-9.883-16.883-16.553-30.636-38.131-27.836-63.167 8.565-76.755 232.654-69.013 232.654 3.871 0 39.613-41.425 56.249-54.108 61.19-2.882 1.071-4.694 3.953-4.529 7 .247 4.283 4.282 7.33 8.483 6.424 77.249-16.141 109.285-102.285 47.025-142.804z"
        className="fillBlack"
        fill="#000"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M253.439 41.028s-8.236 24.706-1.647 42.001M238.038 56.84s-.329 16.306 2.965 23.718"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M352.265 298.8s10.459-14.577 17.624-27.177c1.647-2.882 5.6-3.459 7.989-1.153 6.835 6.506 17.459 20.589 25.036 33.766.659 1.235-.824 2.47-1.894 1.482-5.683-5.435-16.554-14.083-22.978-18.118a2.227 2.227 0 0 0-3.047.659c-2.059 3.212-6.506 11.035-12.024 19.6"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M143.823 239.257s2.224 2.883 17.872-2.306c8.647-2.882 5.929 2.306-2.718 5.518-.659.247-.741 1.236 0 1.483 4.118 1.729 13.918 5.682 20.506 6.917 1.73.33 1.565 2.389 0 2.306-2.717-.082-14.824-2.058-17.871-3.129 7 3.871 12.848 4.2 17.213 6.588.823.494 1.317.824 1.4 1.153.329 2.306-13.918 0-21.742-2.306-7.824-2.306 14.741 5.436 16.306 7.742 1.565 2.306-24.789-4.612-36.483-17.048.082.083.906-4.529 5.517-6.918zM294.863 240.246s-10.129-5.518-17.541-7.412c-7.412-1.812 6.506 7.412 8.317 8.318 1.812.906-23.141 8.318-24.953 12.024-1.812 3.706 14.824-2.8 18.447-2.8 3.624 0-18.447 8.317-18.447 12.023 0 3.706 12.93-1.811 19.436-4.611 0 0-11.118 4.611-10.13 6.506.906 1.811 15.812-4.283 26.848-15.648.659-.659.988-1.565.988-2.471-.082-1.811-.494-4.694-2.965-5.929z"
        fill="#F2F2F2"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M241.991 217.186s12.683-4.2 13.918 1.565c1.071 5.106-12.271-1.4-18.694-2.965-6.424-1.565-11.283 1.976-6.259 4.282 2.306 1.153 7.494-.741 11.035-2.882z"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M232 247.5c1.333.333 4.6 1.8 7 5 3 4 3 15.5 4.5 24.5M296.5 346.5c1 2 8.5-9 9.5-7 .833.833 2.1 4.2.5 11m4-9c.8 2 1.333 7 2.5 8"
        stroke="#000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(HugsIllustration);
